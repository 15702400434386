import React, { useState } from 'react';
import clsx from 'clsx';
import Form from 'react-bootstrap/Form';


export default function InputFile(props) {
  const {
    id,
    value,
    getValue,
    className,
    errorText,
    wrapperClassName,
    ...others
  } = props;

  const [hasError, setHasError] = useState(false);

  const onChangeHandler = (e) => {
    const file = e.target.files[0];
    if (getValue) {
      getValue(file, e);
    }
  }

  return (
    <div className={clsx(
      'dInputFileWrapper',
      wrapperClassName,
    )}
    >
      <Form.File id={id} onChange={onChangeHandler} {...others} />
      {hasError && (
        <Form.Control.Feedback type="invalid">
          {errorText}
        </Form.Control.Feedback>
      )}
    </div>
  );
}

InputFile.propTypes = {};

InputFile.defaultProps = {};
