import React, {useEffect, useState} from 'react';
import { Modal, Button } from '../index';
import { Trans } from 'react-i18next';

const ConfirmAlert = (props) => {
  const { show, onConfirm, onClose, message, confirmText, ignoreText, title, getStatus } = props;
  const [open, setOpen] = useState(show);
  useEffect(() => {
    setOpen(show);
    }, [show]);
   useEffect(() => {
     if (getStatus) {
       getStatus(open);
     }
   }, [open]);

  function handleClose() {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }

  function handleAccept() {
    setOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  }

  return (
    <Modal
      open={open}
      title={title}
      onClose={handleClose}
      className="ConfirmModal"
      closeBackdropOnClick={false}
      action={(
        <div className="action">
          <Button variant="outline" onClick={handleClose} className="mx-2">
            {ignoreText || <Trans i18nKey="confirm_Modal.IGNORE">Ignore</Trans>}
          </Button>

          <Button onClick={handleAccept}>
            {confirmText || <Trans i18nKey="confirm_Modal.CONFIRM">Confirm</Trans>}
          </Button>
        </div>
      )}
    >
      <h6 className="align-center message mt-3">{message}</h6>
    </Modal>
  );
};

export default ConfirmAlert;
