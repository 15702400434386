import 'whatwg-fetch';
import logger from './logger'

export default (method, url, data, unauthorizedRedirect = true) => {
  let body;
  if (data) {
    try {
      body = JSON.stringify(data)
    } catch (err) {
      logger.error(err);
    }
  }

  return fetch(`${url}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((res) => {
    if (unauthorizedRedirect && res.status === 401) {
      window.location.href = '/';
    } else {
      try{
        return res.json()
      } catch(nothing) {
        return null;
      }
    }
  });
};
