const sideMenu = [
  {
    name: 'Dashboard',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.DASHBOARD',
    path: '/dashboard',
    module: 'dashboard',
  },
  {
    name: 'Terminal',
    icon: 'icon-dashboard-1',
    translate: 'sidebar.nav.TERMINAL',
    path: '/terminal',
    module: 'terminal',
  },
  {
    name: 'Campaigns',
    icon: 'icon-protest',
    translate: 'sidebar.nav.campaigns.CAMPAIGNS',
    submenu: [
      {
        name: 'Autopilot',
        translate: 'sidebar.nav.campaigns.AUTOPILOT',
        path: '/autopilot/status',
        module: 'autopilot',
      },
      // {
      //   name: 'Promotions',
      //   translate: 'sidebar.nav.campaigns.PROMOTIONS',
      //   path: '/promotions',
      //   module: 'promotions',
      // },
      // {
      //   name: 'Announcements',
      //   translate: 'sidebar.nav.campaigns.ANNOUNCEMENTS',
      //   path: '/announcements',
      //   module: 'announcements',
      // },
      {
        name: 'Announcements',
        translate: 'sidebar.nav.campaigns.ANNOUNCEMENTS_EMAIL',
        path: '/announcements/email',
        module: 'announcements',
      },
      // {
      //   name: 'SMS Announcements',
      //   translate: 'sidebar.nav.campaigns.ANNOUNCEMENTS_SMS',
      //   path: '/announcements/sms',
      //   module: 'announcements',
      // },
      // {
      //   name: 'Network Reach',
      //   translate: 'sidebar.nav.campaigns.NETWORK',
      //   path: '/network',
      //   module: 'matching',
      // }
    ],
  },
  {
    name: 'Texting',
    icon: 'icon-mobile-phone',
    translate: 'sidebar.nav.texting.TEXTING',
    submenu: [
      {
        name: 'Compose',
        translate: 'sidebar.nav.texting.COMPOSE',
        path: '/texting/compose',
        module: 'ui-samples',
      },
      {
        name: 'Chats',
        translate: 'sidebar.nav.texting.CHATS',
        path: '/texting/chats',
        module: 'ui-samples',
      },
    ],
  },
  {
    name: 'Survey',
    icon: 'icon-support',
    translate: 'sidebar.nav.SUPPORT',
    path: '/survey',
    module: 'survey',
  },
  {
    name: 'Web Page',
    icon: 'icon-www',
    translate: 'sidebar.nav.WEBPAGE',
    path: '/webpages',
    module: 'webpages',
  },
  {
    name: 'Clients',
    icon: 'icon-dashboard-2',
    translate: 'sidebar.nav.CLIENTS',
    path: '/clients',
    module: 'clients',
  },
  {
    name: 'Activities',
    icon: 'icon-dashboard-4',
    translate: 'sidebar.nav.ACTIVITIES',
    path: '/activities',
    module: 'activities',
  },
  {
    name: 'Referral',
    icon: 'icon-diamond',
    translate: 'sidebar.nav.REFERRAL',
    path: '/referral',
    module: 'referral',
  },
  {
    name: 'Settings',
    icon: 'icon-gear',
    translate: 'sidebar.nav.settings.SETTINGS',
    submenu: [
      {
        name: 'Profile',
        translate: 'sidebar.nav.settings.PROFILE',
        path: '/profile',
        module: 'profile',
      },
      {
        name: 'Definitions',
        translate: 'sidebar.nav.settings.DEFINITIONS',
        path: '/definitions',
        module: 'definitions',
      },
      {
        name: 'Products',
        translate: 'sidebar.nav.settings.PRODUCTS',
        path: '/products',
        module: 'products',
      },
      {
        name: 'Rewards',
        translate: 'sidebar.nav.settings.REWARDS',
        path: '/rewards',
        module: 'rewards',
      },
      {
        name: 'Autopilot',
        translate: 'sidebar.nav.settings.AUTOPILOT',
        path: '/autopilot',
        module: 'autopilot',
      },
      {
        name: 'Users',
        translate: 'sidebar.nav.settings.USERS',
        path: '/users',
        module: 'users',
      },
      {
        name: 'Custom Style',
        translate: 'sidebar.nav.settings.CUSTOM_STYLE',
        path: '/custom-style',
        module: 'ui-samples',
      },
    ],
  },
];

export default sideMenu;
