import React from 'react';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';

export default function FormatText(props) {
  const {children, className,...other} = props;
  return (
    <NumberFormat
      type="text"
      value={children}
      className={clsx('dFormatTxt',className)}
      {...other}
    />
  )
}
