import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

const VARIANT = {
  outline: 'outline',
  standard: 'standard',
};
const SIZE = {
  small: 'small',
  medium: 'medium',
};

const defaultStyles = {
  // indicatorsContainer: (styles) => ({...styles, display: 'none'}),
  valueContainer: (styles) => ({...styles, padding: '0 0.625rem'}),
  control: (styles) => {
    return {
      ...styles,
      minHeight: 'unset',
      boxShadow: 'none',
      // borderColor: isFocused ? '#d2ab67' : 'hsl(0,0%,80%)'
    }
  },
  singleValue: (styles) => ({...styles, fontSize: '0.875rem'}),
  input: (styles) => {
    return {
      ...styles,
      padding: '0.5rem 0.3rem',
    };
  },
  menuList: (styles) => ({
    ...styles,
    fontSize: '0.9rem'
  }),
  menu: (styles) => ({...styles, zIndex: 9999}),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#d2ab67" : null,
      color: isFocused ? "#fff" : '#000'
    };
  }
};

export default function Select(props) {
  const {
    id,
    size,
    label,
    color,
    value,
    style,
    isRtl,
    search,
    native,
    onFocus,
    onBlur,
    variant,
    options,
    loading,
    multiple,
    readOnly,
    getValue,
    fullWidth,
    clearable,
    creatable,
    className,
    validators,
    placeholder,
    searchLabel,
    noOptionText,
    isSearchable,
    labelClassName,
    wrapperClassName,
    disableOptionText,
    ...other
  } = props;
  const [val, setVal] = useState(value);
  const [active, setActive] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setVal(value);
    if (value && !active) {
      setActive(true);
    }
  }, [value]);

  function handleChange(value, actionMeta) {
    setVal(val);
    if (value) {
      setActive(true);
    }
    if (getValue && value) {
      let vals = value.value;
      if (multiple) {
        vals = [];
        for(let i in value) {
          vals.push(value[i].value);
        }
      }
      getValue(vals, actionMeta, value);
    }
  }

  function onBlurHandler(event) {
    if (!val) {
      setActive(false);
    }
    if (onBlur) {
      onBlur(event);
    }

    if (validators && validators.length){
      setHasError(true);
    }
  }

  function onfocusHandler(event) {
    setActive(true);
    if (onFocus) {
      onFocus(event);
    }
  }

  function initValue() {
    if(!Array.isArray(val)) {
      return val && options && options.filter((option) => option.value === val)
    } else if (options){
      let values = [];
      for (let i in val) {
        values= [...values, ...options.filter((option) => option.value === val[i])];
      }
      return values;
    }
  }

  const selectOption = initValue();
  const bodyElement = document.getElementsByTagName('body');
  const rtl = isRtl || bodyElement && bodyElement.classList && bodyElement.classList.contains('rtl');

  return (
    <div className={clsx(
      size,
      variant,
      wrapperClassName,
      fullWidth && 'fullWidth',
      'dInputWrapper dSelectBoxWrapper',
    )}
    >
      {!creatable ? (
        <>
          <ReactSelect
            id={id}
            isSearchable={isSearchable}
            isRtl={rtl}
            options={options}
            isMulti={multiple}
            isLoading={loading}
            value={selectOption}
            isDisabled={readOnly}
            isClearable={clearable}
            noOptionsMessage={() => noOptionText || 'no Option'}
            onChange={handleChange}
            placeholder={placeholder}
            styles={style ? style : defaultStyles}
            {...other}
          />
          <label
            className={clsx(
              active && 'active',
              labelClassName
            )}
          >
            {label}
          </label>
        </>
      ) : (
        <CreatableSelect
          id={id}
          isSearchable={isSearchable}
          isRtl={isRtl}
          options={options}
          isMulti={multiple}
          isLoading={loading}
          value={selectOption}
          isDisabled={readOnly}
          isClearable={clearable}
          onChange={handleChange}
          onBlur={onBlurHandler}
          onFocus={onfocusHandler}
          placeholder={placeholder}
          styles={style ? style : defaultStyles}
          {...other}
        />
      )}
    </div>
  );
}
Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.oneOfType(
    [PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        value: PropTypes.any,
        icon: PropTypes.any,
        disable: PropTypes.bool,
        checked: PropTypes.bool,
      }),
    ), PropTypes.any]),
  classes: PropTypes.object,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  search: PropTypes.bool,
  className: PropTypes.string,
  searchLabel: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZE)),
  readOnly: PropTypes.bool,
  isRtl: PropTypes.bool,
  clearable: PropTypes.bool,
  loading: PropTypes.bool,
  creatable: PropTypes.bool,
};
Select.defaultProps = {
  variant: VARIANT.outline,
  search: true,
  clearable: false,
  placeholder: '',
  isSearchable: false,
  options: [{label:'', value:''}],
};
