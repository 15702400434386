import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import query from '../../utils/query';
import { withNamespaces, Trans } from 'react-i18next';

let interval;

class Notification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      showMessage: true,
    };
  }

  componentDidMount() {
    const { account } = this.props;
    if (account.isLoggedIn) {
      this.getNotification();
      if (!interval) {
        interval = setInterval(() => {
          this.getNotification();
        }, 60000);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  getNotification() {
    query('GET', '/v1/notifications').then((json) => {
      const { message } = this.state;
      if (json.success && json.message !== message) {
        this.setState({
          message: json.message,
          showMessage: true,
        }
        );
      }
    });
  }

  removeAlert() {
    this.setState({
      showMessage: false,
    });
  }

  render() {
    const {
      message,
      showMessage,
    } = this.state;

    if (message && showMessage) {
      return (
        <div style={{ paddingTop: "25px" }}>
          <div className="alert alert-warning fade show">
            {message}
            <button type="button" class="close" data-dismiss="alert" onClick={() => this.removeAlert()}>x</button>
          </div>
        </div>
      )
    }

    return ('');
  }
}

Notification.propTypes = {
  account: PropTypes.shape().isRequired,
  t: PropTypes.func,
};

Notification.defaultProps = {
  t: () => { },
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});
export default connect(mapStateToProps)(withNamespaces('translations')(Notification));
