import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import query from '../../utils/query';
import Footer from "../../components/theme/Footer";
import Content from "../../components/theme/Content";
import * as action from '../../store/actions/action';
import Loading from '../../components/theme/Loading';
import WithoutActiveOrganization from '../public/content/WithoutActiveOrganization';
import Sidebar from '../../components/theme/Sidebar';
import PageLoader from '../../components/common/PageLoader';
import RouteModule from '../../components/common/RouteModule';
import { theme, signupModule, privacy, terms } from '../../config';
import { getDirection, getLanguage, setLanguage } from '../../utils/localeTools';
import {BrowserRouter as Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import {addBodyClass, getModules, setDirectionClass, setLanguageClass} from '../../utils/utils';

import 'react-toastify/dist/ReactToastify.min.css';
import '../../components/3rdParties/Vendor';
import ('../../styles/themes/' + theme + '.css');
import '../../styles/style.scss';

import {getFromStorage} from "../../utils/storage";

const Sample = lazy(() => import('../Sample'));
const Signup = lazy(() => import('../auth/content/Signup'));
const Registration = lazy(() => import('../auth/content/Registration'));
const Login = lazy(() => import('../auth/content/Login'));
const PasswordRecovery = lazy(() => import('../auth/content/PasswordRecovery'));
const PasswordSetup = lazy(() => import('../auth/content/PasswordSetup'));
const Report = lazy(() => import('../public/content/Report'));
const Privacy = lazy(() => import('../public/content/Privacy'));
const Terms = lazy(() => import('../public/content/Terms'));

const Subscription = lazy(() => import('../public/content/Subscription'));
const Review = lazy(() => import('../public/content/Review'));
const Faq = lazy(() => import('../faq/content/Faq'));
const Help = lazy(() => import('../public/content/Help'));

const Dashboard = lazy(() => import('../dashboard/content/Dashboard'));

const Terminal = lazy(() => import('../terminal/content/Terminal'));
const TerminalAddNewUser = lazy(() => import('../terminal/content/TerminalAddNewUser'));
const TerminalRedeem = lazy(() => import('../terminal/content/TerminalRedeem'));

const TerminalPayments = lazy(() => import('../terminal/content/TerminalPayments'));
const TerminalPoints = lazy(() => import('../terminal/content/TerminalPoints'));
const TerminalProducts = lazy(() => import('../terminal/content/TerminalProducts'));

const AutopilotStatus = lazy(() => import('../autopilot/component/AutopilotStatus'));
const Announcements = lazy(() => import('../announcements/content/Announcements'));
const AnnouncementsAdd = lazy(() => import('../announcements/content/AnnouncementsAdd'));
const AnnouncementsEmail = lazy(() => import('../announcements/content/AnnouncementsEmail'));
const AnnouncementsSms = lazy(() => import('../announcements/content/AnnouncementsSms'));

const Survey = lazy(() => import('../survey/content/Survey'));
const Users = lazy(() => import('../users/content/Users'));
const UsersRole = lazy(() => import('../users/content/UsersRole'));
const UsersForm = lazy(() => import('../users/content/UsersForm'));
const Bugs = lazy(() => import('../bugs/content/Bugs'));
const Feature = lazy(() => import('../feature/content/Feature'));

const Clients = lazy(() => import('../client/content/Clients'));
const ClientAdd = lazy(() => import('../client/content/AddClients'));
const ClientEdit = lazy(() =>   import('../client/content/EditClients'));

const Activities = lazy(() => import('../activities/content/Activities'));
const Webpage = lazy(() => import('../webpage/content/Webpage'));
const Profile = lazy(() => import('../profile/content/Profile'));
const Autopilot = lazy(() => import('../autopilot/component/Autopilot'));
const AutopilotEdit = lazy(() => import('../autopilot/component/AutopilotEdit'));
const Rewards = lazy(() => import('../rewards/content/Rewards'));
const Definitions = lazy(() => import('../definitions/content/Definitions'));
const Account = lazy(() => import('../account/content/Account'));
const Billing = lazy(() => import('../billing/content/Billing'));
const Messages = lazy(() => import('../message/content/Messages'));
const CustomStyle = lazy(() => import('../customStyle/content/CustomStyle'));
const Products = lazy(() => import('../products/content/Products'));
const Import = lazy(() => import('../import/content/Import'));
const Referral = lazy(() => import('../referral/content/Referral'));
const StartupGuide = lazy(() => import('../startupGuide/content/StartupGuide'));
const FirstLogin = lazy(() => import('../firstLogin/content/FirstLogin'));
const Callback = lazy(() => import('../public/content/Callback'));

const Compose = lazy(() => import('../texting/content/Compose'));
const Chats = lazy(() => import('../texting/content/Chats'));

const publicPages = [
  '',
  'login',
  'signup',
  'registration',
  'password-recovery',
  'first-login',
  'api-docs',
  'review',
  'report',
  'r', //Referral Link
  'privacy',
  'terms',
  'help',
  'subscription',
  'callback',
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hasValidSession: getFromStorage('login'),
    };
    this.failedAuth = this.failedAuth.bind(this);
  }

  componentDidMount() {
    addBodyClass('theme-light');

    if (this.state.hasValidSession) {
      this.setState({ isLoading: true });
      query('GET', '/v1/me', null, false)
        .then(async (json) => {
          const {onLogin} = this.props;
          if (json.success) {
            await onLogin({...json, isLoggedIn: true});
            this.setState({
              isLoading: false,
            });
          } else {
            this.failedAuth();
          }
        });
    } else {
      this.failedAuth();
    }
  }

  failedAuth() {
    const { account, onLogout } = this.props;
    onLogout();
    account.user = {};
    account.isLoggedIn = false;
    const host = `${window.location.protocol}//${window.location.host}`;
    const href = window.location.href.replace(host, '');
    const params = href.split('/');
    if (!publicPages.includes(params[1])) {
      window.location.href = '/login';
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { account, theme, onCloseAside } = this.props;
    const language = account.user.defaultLanguage || getLanguage();
    setLanguage(language);
    setDirectionClass(getDirection(language));
    setLanguageClass(language);

    const mainClass = theme.openAside ? 'main collapsed-sidebar' : 'main';

    if (this.state.isLoading === true && this.state.hasValidSession ) {
      return (
        <div className={mainClass}>
          <Loading />
        </div>
      );
    }

    // PUBLIC PAGES

    if (account.isLoggedIn === false) {
      return (
        <Router>
          <div>
            <Suspense fallback={<PageLoader/>}>
              <Switch>
                {/* Removes trailing slashes */}
                <Route
                  path="/:url*(/+)"
                  exact
                  strict
                  render={({ location }) => <Redirect to={location.pathname.replace(/\/+$/, '')} />}
                />
                {/* Removes duplicate slashes in the middle of the URL */}
                <Route
                  path="/:url(.*//+.*)"
                  exact
                  strict
                  render={({ match }) => (
                    <Redirect to={`/${match.params.url.replace(/\/\/+/, '/')}`} />
                  )}
                />
                <Route exact path="/login" component={Login} />
                <Route exact path={privacy} component={Privacy} />
                <Route exact path={terms} component={Terms} />
                <Route exact path="/signup/:referralCode?" component={Signup} />
                <Route exact path="/registration/:referralCode?" component={Registration} />
                <Route
                  exact
                  path="/r/:referralCode"
                  render={({ match }) => <Redirect to={`/${signupModule}/${match.params.referralCode}`} />}
                />

                <Route exact path="/password-recovery/:token?" component={PasswordRecovery} />
                <Route exact path="/password-setup/:token" component={PasswordSetup} />
                <Route
                  path="/subscription/:type?/:uuid?"
                  component={(props) => <Subscription {...props} />}
                />
                <Route
                  path="/review/:uuid?"
                  component={(props) => <Review {...props} />}
                />
                <Route
                  path="/report/:type?/:uuid?"
                  component={(props) => <Report {...props} />}
                />
              <Route
                path="/callback/:result?/:Id?"
                component={(props) =>
                  <Content>
                    <Callback {...props} />
                  </Content>
                }/>
                <Route path="*">
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </Suspense>
          </div>
        </Router>
      );
    }

    if (!account.organization || !account.permissions) {
      return (
          <WithoutActiveOrganization />
      );
    }

    const permissions = account.permissions
      ? Object.keys(account.permissions)
      : [];
    let modules = getModules(account);
    modules = modules.filter((module) => permissions.includes(module));
    const defaultRoute = account.defaultRoute || `/${modules[0]}`;

    // DISABLED ORGANIZATION PAGES

    if (!account.organization.activeSubscription
      && !account.organization.inGracePeriod
      && !account.organization.inTrialPeriod
      ) {
      return (
        <Router>
          <link rel="stylesheet" type="text/css" href="/v1/app/theme" />
          <div className={mainClass}>
            <script>{account.organization.customJs}</script>
            <style>{account.organization.customCss}</style>
            <Sidebar />
            <div className="sidebar-cover" onClick={onCloseAside} />
            <Suspense fallback={<PageLoader />}>
              <Switch>
                <Route
                  path="/billing"
                  component={(props) =>
                    <Content>
                      <Billing {...props} />
                    </Content>
                  }
                />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/terms" component={Terms} />
                <Route
                path="/help/:id?"
                  component={(props) =>
                    <Content>
                      <Help {...props} />
                    </Content>
                  }
              />
                <Route path="*">
                  <Redirect to="/billing" />
                </Route>
              </Switch>
            </Suspense>
            <Footer />
          </div>
        </Router>
      );
    }

    // MAIN PRIVATE PAGES

    return (
      <Router>
        {/* TODO fix theme */}
        {/*<link rel="stylesheet" type="text/css" href="/v1/app/theme" />*/}
        <div className={mainClass}>
          <script>{account.organization.customJs}</script>
          <style>{account.organization.customCss}</style>
          <Sidebar />
          <div className="sidebar-cover" onClick={onCloseAside} />
          <Suspense fallback={<PageLoader />}>
            <Switch>
              {/* Removes trailing slashes */}

              <RouteModule
                module="dashboard"
                path="/sample"
                component={(props) =>
                  <Content>
                    <Sample {...props} />
                  </Content>
                }
              />

              <Route
                path="/:url*(/+)"
                exact
                strict
                render={({ location }) =>
                  <Content>
                    <Redirect to={location.pathname.replace(/\/+$/, '')} />
                  </Content>
                }
              />
              {/* Removes duplicate slashes in the middle of the URL */}
              <Route
                path="/:url(.*//+.*)"
                exact
                strict
                render={({ match }) => (
                  <Content>
                    <Redirect to={`/${match.params.url.replace(/\/\/+/, '/')}`} />
                  </Content>
                )}
              />

              <RouteModule
                module="dashboard"
                path="/dashboard"
                component={(props) =>
                  <Content>
                    <Dashboard {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="terminal"
                path="/terminal/payments/:phone?/:visitId?"
                component={(props) =>
                  <Content>
                    <TerminalPayments {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="terminal"
                path="/terminal/products/:phone?/:visitId?"
                component={(props) =>
                  <Content>
                    <TerminalProducts {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="terminal"
                path="/terminal/points/:phone?/:visitId?"
                component={(props) =>
                  <Content>
                    <TerminalPoints {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="terminal"
                path="/terminal/redeem/:phone?/:visitId?"
                component={(props) =>
                  <Content>
                    <TerminalRedeem {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="terminal"
                path="/terminal/:action?/:visitId?"
                component={(props) =>
                  <Content>
                    <Terminal {...props} />
                  </Content>
                }
              />
              <RouteModule
                module="terminal"
                path="/client/addFromTerminal/:phone?"
                component={(props) =>
                  <Content>
                    <TerminalAddNewUser {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="ui-samples"
                path="/texting/chats"
                component={(props) =>
                  <Content>
                    <Chats {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="ui-samples"
                path="/texting/compose"
                component={(props) =>
                  <Content>
                    <Compose {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="autopilot"
                path="/autopilot/status"
                component={(props) =>
                  <Content>
                    <AutopilotStatus {...props} />
                  </Content>
                }
              />

              {/*<RouteModule*/}
              {/*  exact*/}
              {/*  module="announcements"*/}
              {/*  path="/announcements"*/}
              {/*  component={(props) =>*/}
              {/*    <Content>*/}
              {/*      <Announcements {...props} />*/}
              {/*    </Content>*/}
              {/*  }*/}
              {/*/>*/}

              {/*<RouteModule*/}
              {/*  module="announcements"*/}
              {/*  path="/announcements/add/:announcementId?"*/}
              {/*  component={(props) =>*/}
              {/*    <Content>*/}
              {/*      <AnnouncementsAdd {...props} />*/}
              {/*    </Content>*/}
              {/*  }*/}
              {/*/>*/}

              <RouteModule
                module="announcements"
                path="/announcements/email/:action?/:announcementId?"
                component={(props) =>
                  <Content>
                    <AnnouncementsEmail {...props} />
                  </Content>
                }
              />

              {/*<RouteModule*/}
              {/*  module="announcements"*/}
              {/*  path="/announcements/sms/:action?/:announcementId?"*/}
              {/*  component={(props) =>*/}
              {/*    <Content>*/}
              {/*      <AnnouncementsSms {...props} />*/}
              {/*    </Content>*/}
              {/*  }*/}
              {/*/>*/}

              <RouteModule
                module="survey"
                path="/survey"
                component={(props) =>
                  <Content>
                    <Survey {...props} />
                  </Content>
                }
              />

              <RouteModule
                exact
                module="users"
                path="/users"
                component={(props) =>
                  <Content>
                    <Users {...props} />
                  </Content>
                }
              />
              <RouteModule
                module="users"
                path="/users/role/:uuid?"
                component={(props) =>
                  <Content>
                    <UsersRole {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="users"
                path="/users/form/:uuid?"
                component={(props) =>
                  <Content>
                    <UsersForm {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="clients"
                path="/clients"
                component={(props) =>
                  <Content>
                    <Clients {...props} />
                  </Content>
              }/>

              <RouteModule
                module="clients"
                path="/clientsAdd"
                component={(props) =>
                  <Content>
                    <ClientAdd {...props} />
                  </Content>
              }/>

              <RouteModule
                module="clients"
                path="/clients/edit"
                component={(props) =>
                  <Content>
                    <ClientEdit {...props} />
                  </Content>
                }/>

              <RouteModule
                module="import"
                path="/import/:module"
                component={(props) =>
                  <Content>
                    <Import {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="activities"
                path="/activities/:clientId?"
                component={(props) =>
                  <Content>
                    <Activities {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="webpages"
                path="/webpages"
                component={(props) =>
                  <Content>
                    <Webpage {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="profile"
                path="/profile"
                component={(props) =>
                  <Content>
                    <Profile {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="autopilot"
                path="/autopilot"
                component={(props) =>
                  <Content>
                    <Autopilot {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="autopilot"
                path="/autopilotEdit"
                component={(props) =>
                  <Content>
                    <AutopilotEdit {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="rewards"
                path="/rewards"
                component={(props) =>
                  <Content>
                    <Rewards {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="definitions"
                path="/definitions"
                component={(props) =>
                  <Content>
                    <Definitions {...props} />
                  </Content>
                }
              />

              <Route
                module="account"
                path="/account"
                component={(props) =>
                  <Content>
                    <Account {...props} />
                  </Content>
                }
              />

              <Route
                module="messages"
                path="/messages/:messageId?"
                component={(props) =>
                  <Content>
                    <Messages {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="billing"
                path="/billing"
                component={(props) =>
                  <Content>
                    <Billing {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="products"
                path="/products"
                component={(props) =>
                  <Content>
                    <Products {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="ui-samples"
                path="/custom-style"
                component={(props) =>
                <Content>
                  <CustomStyle {...props} />
                </Content>
              }/>

              <Route
                path="/callback/:result?/:Id?"
                component={(props) =>
                  <Content>
                    <Callback {...props} />
                  </Content>
                }/>

              <RouteModule
                module="referral"
                path="/referral"
                component={(props) =>
                <Content>
                  <Referral {...props} />
                </Content>
              }/>

              <RouteModule
                module="startup-guide"
                path="/startup-guide"
                component={(props) =>
                <Content>
                  <StartupGuide {...props} />
                </Content>
              }/>

              <RouteModule
                module="first-login"
                path="/first-login"
                component={(props) =>
                <Content>
                  <FirstLogin {...props} />
                </Content>
              }/>

              <RouteModule
                module="help"
                path="/help/:id?"
                component={(props) =>
                  <Content>
                    <Help {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="faq"
                path="/faq"
                component={(props) =>
                  <Content>
                    <Faq {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="bug-report"
                path="/bug-report"
                component={(props) =>
                  <Content>
                    <Bugs {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="feature-request"
                path="/feature-request"
                component={(props) =>
                  <Content>
                    <Feature {...props} />
                  </Content>
                }
              />

              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route path="/*">
                <Redirect to={defaultRoute} />
              </Route>
            </Switch>
          </Suspense>

          <Footer />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  theme: state.themeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  onLogin: (payload) => dispatch({ ...payload, type: action.LOGIN_USER }),
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
  onCloseAside: () => dispatch({ type: action.CLOSE_ASIDE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
