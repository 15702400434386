import React from 'react';
import ReactCol from 'react-bootstrap/Col';
import ReactRow from 'react-bootstrap/Row'

export function Col(props) {
  return <ReactCol {...props} />
}

export function Row(props) {
  return <ReactRow {...props} />
}

