import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, Trans } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import MobileHeader from './MobileHeader';

import * as action from '../../store/actions/action';
import { logoWhite } from '../../config';
import sideMenu from '../../config/side-menu';
import {getModules} from "../../utils/utils";
import clsx from 'clsx';

const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>{item.heading}</span>
  </li>
);
const SidebarItem = ({ item, isActive, handler }) => (
  <li onClick={handler} className={`item ${isActive ? 'active' : ''}`}>
    <Link to={item.path} className="item-title">
      {item.icon && (
        <span className="icon">
          <i className={item.icon} />
        </span>
      )}
      <span className="text">
        <Trans i18nKey={item.translate}>{item.name}</Trans>
      </span>
    </Link>
  </li>
);

const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
  <li className={`item ${isActive ? 'active' : ''}`}>
    <div className="item-title" onClick={handler} onKeyPress={handler} role="button" tabIndex={0}>
      {item.icon && (
        <span className="icon">
          <i className={item.icon} />
        </span>
      )}
      <span className="text">
        <Trans i18nKey={item.translate}>{item.name}</Trans>
      </span>

      <i className={clsx('fas', !isOpen ? 'fa-angle-down' : 'fa-angle-up', 'collapse-icon', isActive && 'active')} />
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        {children}
      </ul>
    </Collapse>
  </li>
);

// const SidebarSubHeader = ({item}) => (
//   <li className="sidebar-subnav-header">{item.name}</li>
// )

class Sidebar extends Component {
  constructor(props) {
    super(props);

    const modules = getModules(this.props.account);
    const permissions = this.props.account.permissions
      ? Object.keys(this.props.account.permissions)
      : [];

    this.state = {
      collapse: {},
      modules: modules.filter((module) => permissions.includes(module)),
    };
  }

  closeAside = () => {
    this.props.onCloseAside();
  };

  getSubRoutes = (item) => item.submenu.map(({ path }) => path);

  navigator = (route) => {
    // this.props.history.push(route.replace('#','')); // remove '#' in case of use HashRouter
  };

  itemType = (item) => {
    if (item.heading) return 'heading';
    if (item.submenu) return 'submenu';
    return 'menu';
  };

  hasAccess = (item) => {
    switch (this.itemType(item)) {
      case 'header':
        return true;

      case 'submenu':
        const modules = Array.isArray(item.submenu)
          ? item.submenu.map((subItem) => subItem.module)
          : [];
        for (const module of modules) {
          if (this.state.modules.includes(module)) return true;
        }
        return false;

      default:
        const { module } = item;
        return !module || this.state.modules.includes(module);
    }
  };

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return !!paths.includes(this.props.location.pathname);
  }

  toggleItemCollapse(stateName) {
    for (const c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName) {
        this.setState({
          collapse: {
            [c]: false,
          },
        });
      }
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName],
      },
    });
  }

  render() {
    return (
      <>
        <MobileHeader />
        <aside className="main-sidebar">
          <div className="inner-scroll">
            <button className="menu-button" onClick={this.closeAside} type="button">
              <i className="icon-close" />
            </button>
            <figure className="logo">
              <img src={logoWhite} alt="logo" />
            </figure>

            <figure className="avatar">
              <img
                src={
                  this.props.account.organization && this.props.account.organization.logo
                    ? this.props.account.organization.logo
                    : '/uploads/default.jpg'
                }
                alt="Organization Logo"
              />
            </figure>
            <h6 className="name">
              {
                this.props.account.organization ?
                  this.props.account.organization.shortName || this.props.account.organization.name
                  : null
              }
            </h6>

            <div className="content-wrapper">
              <ul className="navigator">
                {sideMenu.map((item, i) => {
                  if (this.hasAccess(item)) {
                    switch (this.itemType(item)) {
                      case 'heading':
                        return <SidebarItemHeader item={item} key={i} />;
                      case 'submenu':
                        return [
                          <SidebarSubItem
                            item={item}
                            isOpen={this.state.collapse[item.name]}
                            handler={this.toggleItemCollapse.bind(this, item.name)}
                            isActive={this.routeActive(this.getSubRoutes(item))}
                            key={i}
                          >
                            {item.submenu.map((subItem, i) => (this.hasAccess(subItem) ? (
                              <SidebarItem
                                handler={this.closeAside}
                                key={i}
                                item={subItem}
                                isActive={this.routeActive(subItem.path)}
                              />
                            ) : null))}
                          </SidebarSubItem>,
                        ];
                      default:
                        return (
                          <SidebarItem
                            handler={this.closeAside}
                            isActive={this.routeActive(item.path)}
                            item={item}
                            key={i}
                          />
                        );
                    }
                  }

                  return null;
                })}
              </ul>
            </div>
          </div>
        </aside>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  onCloseAside: () => dispatch({ type: action.CLOSE_ASIDE }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translations')(withRouter(Sidebar)));
