import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import BootStrapBadge from 'react-bootstrap/Badge'

const COLOR = ['primary','secondary','success','warning','danger','info','dark','light'];

export default function Badge(props){
  const { variant, children, className, color, round ,...others } = props;
  return (
    <BootStrapBadge
      className={clsx(
          className
      )}
      variant={color}
      pill={round}
      {...others}
    >
      {children}
    </BootStrapBadge>
  );
}

Badge.propTypes = {
  color: PropTypes.oneOf(COLOR),
  round: PropTypes.bool,
};

Badge.defaultProps = {
  color:'primary',
};
