import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {getModules} from "../../utils/utils";

const RouteModule = (props) => {
  const permissions = props.account.permissions ? Object.keys(props.account.permissions) : [];
  let modules = getModules(props.account);
  modules = modules.filter((module) => permissions.includes(module));

  if (!modules.includes(props.module)) return <Redirect to="/" />;

  return <Route path={props.path} component={props.component} />;
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps)(RouteModule);
