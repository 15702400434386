module.exports = {
  productionMode: process.env.REACT_APP_PRODUCTION_MDOE || false,
  appName: process.env.REACT_APP_NAME || 'Mahaleh',
  logo: process.env.REACT_APP_LOGO || '/images/logo-blue.png',
  logoWhite: process.env.REACT_APP_LOGO_WHITE || process.env.REACT_APP_LOGO || '/images/logo.png',
  theme: process.env.REACT_APP_THEME || 'default',
  sideImage: process.env.REACT_APP_SIDE_IMAGE || process.env.REACT_APP_PLACEHOLDER_SIDE_IMAGE || '/images/login.jpg',
  placeholderSideImage: process.env.REACT_APP_PLACEHOLDER_SIDE_IMAGE || process.env.REACT_APP_SIDE_IMAGE || '/images/login.jpg',
  importSampleFile: process.env.REACT_APP_SAMPLE_FILE || '/file/sample.csv',
  signupModule: process.env.REACT_APP_SIGNUP_MODULE || 'registration', //signup, registration
  signupPageDisabled: process.env.REACT_APP_SIGNUP_PAGE_DISABLED || false,
  registrationPageDisabled: process.env.REACT_APP_REGISTRATION_PAGE_DISABLED || false,
  availableLanguages: process.env.REACT_APP_AVAILABLE_LANGUAGES || 'en,fa,ar', // separate numbers by comma (,)
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || 'fa',

  globalConstant: {
    terminalMode: {
      payments: 'payments',
      products: 'products',
      points: 'points',
    },
  },

  copyright: {
    default: process.env.REACT_APP_COPYRIGHT || 'Copyright © 2022 Mahaleh. All rights reserved.',
    "fa-IR": process.env.REACT_APP_COPYRIGHT_FA || process.env.REACT_APP_COPYRIGHT || 'کلیه حقوق متعلق به سایت محله میباشد.',
  },
  privacy: process.env.REACT_APP_PRIVACY_LINK || '/privacy',
  terms: process.env.REACT_APP_TERMS_LINK || '/terms',
  support: process.env.REACT_APP_SUPPORT_LINK || null,

  helpDisabled: process.env.REACT_APP_HELP_DISABLED || false,
  helpUrl: {
    accounts: {
      default: process.env.REACT_APP_HELP_ACCOUNTS || 'mailto:account.default@mahaleh.ir',
      "en-US": process.env.REACT_APP_HELP_ACCOUNTS_EN || process.env.REACT_APP_HELP_ACCOUNTS || 'mailto:account.en@mahaleh.ir',
      "fa-IR": process.env.REACT_APP_HELP_ACCOUNTS_FA || process.env.REACT_APP_HELP_ACCOUNTS || 'mailto:account.fa@mahaleh.ir',
    },
    businessProfile: {
      businessName: {
        default: process.env.REACT_APP_HELP_BUSINESS_PROFILE_BUSINESS || 'mailto:business.default@mahaleh.ir',
        "en-US": process.env.REACT_APP_HELP_BUSINESS_PROFILE_BUSINESS_EN || process.env.REACT_APP_HELP_BUSINESS_PROFILE_BUSINESS || 'mailto:business.en@mahaleh.ir',
        "fa-IR": process.env.REACT_APP_HELP_BUSINESS_PROFILE_BUSINESS_FA || process.env.REACT_APP_HELP_BUSINESS_PROFILE_BUSINESS || 'mailto:business.fa@mahaleh.ir',
      },
      branchName: {
        default: process.env.REACT_APP_HELP_BUSINESS_PROFILE_BRANCH || 'mailto:branch.default@mahaleh.ir',
        "en-US": process.env.REACT_APP_HELP_BUSINESS_PROFILE_BRANCH_EN || process.env.REACT_APP_HELP_BUSINESS_PROFILE_BRANCH || 'mailto:branch.en@mahaleh.ir',
        "fa-IR": process.env.REACT_APP_HELP_BUSINESS_PROFILE_BRANCH_FA || process.env.REACT_APP_HELP_BUSINESS_PROFILE_BRANCH || 'mailto:branch.fa@mahaleh.ir',
      },
    },
    products: {
      default: process.env.REACT_APP_HELP_PRODUCTS || 'http://help.mahaleh.ir/en/products',
      "en-US": process.env.REACT_APP_HELP_PRODUCTS_EN || process.env.REACT_APP_HELP_PRODUCTS || 'http://help.mahaleh.ir/en/products',
      "fa-IR": process.env.REACT_APP_HELP_PRODUCTS_FA || process.env.REACT_APP_HELP_PRODUCTS || 'http://help.mahaleh.ir/fa/products',
    },
  },

  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || null,
    sampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0,
  }
};
