import React, {useEffect, useState} from 'react';
import Header from '../theme/Header';
import HelpModal from '../help/HelpModal';

export default function Content ({ children }){
  const [help, setHelp] = useState(false);

  useEffect(() => {
    document.onkeyup = (e)=> {
      if (e.altKey && e.which === 72) {
        setHelp(true);
      }
    };
  }, []);

  function handleClose() {
    setHelp(false);
  }

  function handleHelp() {
    setHelp(true);
  }

  return (
    <>
      <main className="main-content p-0">
        <Header />
        <div className="contentWrapper mx-3">
          {children}
        </div>
      </main>
      {help && <HelpModal open={help} getValue={handleClose} />}
      <button className="d-none" id="helpBtn" onClick={handleHelp}>help</button>
    </>
  );
}
