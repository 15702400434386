import React, {useEffect, useState} from 'react';
import ReactFade from 'react-bootstrap/Fade';
import ReactCollapse from 'react-bootstrap/Collapse';

export function Collapse(props) {
  const {children, open, ...other} = props;
  const [openStatus, setOpen] = useState();

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <ReactCollapse in={openStatus} {...other}>
      {children}
    </ReactCollapse>
  );
}
Collapse.propTypes = {};
Collapse.defaultProps = {};

export function Fade(props) {
  const {children, open} = props;
  const [openStatus, setOpen] = useState();

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <ReactFade in={openStatus}>
      {children}
    </ReactFade>
  );
}
Fade.propTypes = {};
Fade.defaultProps = {};
