import i18n from 'i18next';
import { defaultLanguage } from '../config';

const supportedLocales = ['en', 'en-US', 'en-CA', 'ar', 'ar-AE', 'ar-SA', 'fa', 'fa-IR'];
const rtlLocales = ['ar', 'az', 'dv', 'he', 'ku', 'fa', 'ur'];
const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

export function getLanguage() {
  return (
    i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || defaultLanguage || 'en-US'
  );
}

export function setLanguage(language) {
  i18n.changeLanguage(language);
}

export function getShortLocale(language = getLanguage()) {
  return String(language[0] + language[1]).toLowerCase();
}

export function getDirection(language = getLanguage()) {
  return rtlLocales.includes(getShortLocale(language)) ? 'rtl' : 'ltr';
}

export function getSupportedLocales() {
  return supportedLocales;
}

export function getEnglishNumbers(string) {
  let str = string;
  if (typeof str === 'string') {
    str = str.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)).replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
  }

  return str;
}

export function getArabicNumbers(string) {
  let str = string;
  if (typeof str === 'string') {
    for (let index = 0; index < 10; index += 1) {
      str = str.replace(index, arabicNumbers[index]);
    }
  }

  return str;
}

export function getPersianNumbers(string) {
  let str = string;
  if (typeof str === 'string') {
    for (let index = 0; index < 10; index += 1) {
      str = str.replace(index, persianNumbers[index]);
    }
  }

  return str;
}

export function getLocaleNumbers(string, language = getLanguage()) {
  const locale = getShortLocale(language);
  if (locale === 'ar') return getArabicNumbers(string);
  if (locale === 'fa') return getPersianNumbers(string);

  return getEnglishNumbers(string);
}
