import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import parse from 'html-react-parser';
import query from '../../utils/query';
import {Button, Modal} from '../elements';
import { helpDisabled } from '../../config';
import {withNamespaces, Trans} from 'react-i18next';
import { Loader } from '../common/PageLoader';

class HelpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      loading: false,
      Components: '',
      open: props.open,
    };
    this.handleClose = this.handleClose.bind(this);
    this.getComponent = this.getComponent.bind(this);
  }

  componentDidMount() {
    this.getComponent();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
    if (nextProps.id !== this.state.id) {
      this.getComponent();
    }
  }

  handleClose() {
    this.setState({open: false}, this.props.getValue);
  }

  async getComponent() {
    const path = window.location.pathname;
    this.setState({ loading: true });
    const json = await query('GET', `/v1/helps${path}`);
    if (json.success && json.helpTerms) {
      const Components = parse(json.helpTerms);
      this.setState({ Components, loading: false });
    }
  }

  render() {
    if (helpDisabled) return null;

    const {t} = this.props;
    const state = this.state;

    return (
      <Modal
        open={state.open}
        className="helpModal"
        onClose={this.handleClose}
        closeBackdropOnClick={false}
        title={t("help.TITLE", "Help")}
        action={(
          <div className="action">
            <Button variant="outline" onClick={this.handleClose} className="mx-2">
              <Trans i18nKey="confirm_Modal.IGNORE">Ignore</Trans>
            </Button>
          </div>
        )}
      >
        {state.loading ? <Loader /> : state.Components}
      </Modal>
    );
  }
}

HelpModal.propTypes = {
  t: PropTypes.func,
};

HelpModal.defaultProps = {
  t: () => {
  },
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps)(withNamespaces('translations')(HelpModal));
