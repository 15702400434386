import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";

export default function LoadingButton(props) {
    const {loading, children, grow, color,...other } = props;
    const [isLoading, setLoading] = useState(loading);
    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return (
        <Button {...other} disabled={isLoading}>
            {isLoading && <Spinner grow={grow} size="sm" color={color}/>}
            {children}
        </Button>
    );
}
LoadingButton.propTypes = {
    loading: PropTypes.bool,
    grow: PropTypes.bool,
};
LoadingButton.defaultProps = {
    loading: false,
    color: 'light',
};
