import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Accordion as BootstrapAccordion, Card} from 'react-bootstrap';

export default function Accordion(props) {
  const {options, active, wrapperClassName, id, icon} = props;

  const [currentActive, setCurrentActive] = useState(active);

  function handleClick(index) {
    const elem = document.getElementById(`${id}-${index}`);
    elem && elem.click();
    setCurrentActive(index);
  }

  return (
    <div className={wrapperClassName}>
      <BootstrapAccordion defaultActiveKey={active || active === 0 ? active.toString() : ''}>
        {options.map((item, index) => (
          <Card key={index} onClick={()=>handleClick(index)}>
            <Card.Header className={clsx(icon && 'header-icon', 'd-flex')}>
              <BootstrapAccordion.Toggle
                key={index}
                variant="link"
                as={Card.Header}
                id={`${id}-${index}`}
                className="flex-grow-1"
                eventKey={index.toString()}
              >
                {item.title}
              </BootstrapAccordion.Toggle>
              {icon &&
                <i className={
                  clsx(currentActive !== index ? 'fas fa-caret-down' : 'fas fa-caret-up',
                    'cursor-pointer')}
                />
              }
            </Card.Header>
            <BootstrapAccordion.Collapse eventKey={index.toString()}>
              <Card.Body>{item.body}</Card.Body>
            </BootstrapAccordion.Collapse>
          </Card>
        ))}
      </BootstrapAccordion>
    </div>
  );
}

Accordion.propTypes = {
  active: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.any,
  })),
};

Accordion.defaultProps = {
  active: 0,
  id: 'accordion'
};
