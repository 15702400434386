import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import Copyright from '../../../components/common/Copyright';
import * as action from '../../../store/actions/action';
import { logo, placeholderSideImage } from '../../../config';
import query from '../../../utils/query';
import { removeStorage } from "../../../utils/storage";
import { LoadingButton } from '../../../components/elements'

const host = `${window.location.protocol}//${window.location.host}`;

class WithoutActiveOrganization extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout(e) {
    e.preventDefault();
    query('POST', '/v1/auth/logout').then(() => {
      this.props.onLogout();
      this.setState({ logoutStatus: true });
      removeStorage('login');
    });
  }

  render() {
    return (
      <main className="main-content p-0">
        <div className="d-flex login-page">
          <div className="login-page__right-item order-1 order-md-0">
            <figure className="logo">
              <img src={logo} alt="" />
            </figure>
            <h3 className="title">
              <Trans i18nKey="without_active_organization.TITLE">Without Active Organization</Trans>
            </h3>
            <div className="text-center">
              <br />
              <Trans i18nKey="without_active_organization.TEXT">you does't have an Active Organization, please call your manager to give access your account correct permitions</Trans>

              <br />

              <LoadingButton
                type="submit"
                className="btn-login mt-1"
                onClick={this.logout}
              >
                <Trans i18nKey="header.LOGOUT">Logout</Trans>
              </LoadingButton>
            </div>
            <br />
            <p className="footer-login"><Copyright /></p>
          </div>
          <div className="login-page__left-item">
            <img className="image" src={`${host}${placeholderSideImage}`} alt="" />
          </div>
        </div>
      </main>
    );
  }
}

WithoutActiveOrganization.propTypes = {
  t: PropTypes.func,
};

WithoutActiveOrganization.defaultProps = {
  t: () => { },
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
});

export default connect(
  null,
  mapDispatchToProps
)(withNamespaces('translations')(WithoutActiveOrganization));
