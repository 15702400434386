import React from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import { privacy, terms } from "../../config";
import Copyright from '../common/Copyright';

function Footer() {

  function WebLink({url, className, children}) {
    if (url.search('://') === -1) {
      return <Link className={className} to={url}>{children}</Link>
    }

    return <a className={className} href={url} target='_blank'>{children}</a>
  }

  return (
    <p className="main-footer">
      <Copyright />
      <WebLink className="mx-2" url={privacy}>
        <Trans i18nKey="footer.PRIVACY_POLICY">Privacy Policy</Trans>
      </WebLink>
      <WebLink url={terms}>
        <Trans i18nKey="footer.TERMS_OF_USE">Terms of Use</Trans>
      </WebLink>
    </p>
  );
}

export default withNamespaces('translations')(Footer);
