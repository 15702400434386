import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Dropdown as BootstrapDropdown } from "react-bootstrap";

const SIZE = {
  lg: 'lg',
  md:'md',
  sm: 'sm',
};
const COLOR = ['primary','secondary','success','warning','danger','info','dark','light'];
const DIRECTION = ['up', 'down', 'left', 'right'];

export default function Dropdown(props) {
    const { id, className, color, size, title, options, round, dir } = props;

    return (
        <BootstrapDropdown
            className={clsx(round && 'round', size, className)}
            drop={dir}
        >
            <BootstrapDropdown.Toggle variant={color} id={id}>
                {title}
            </BootstrapDropdown.Toggle>

            <BootstrapDropdown.Menu>
                {options.map((item, index)=>{
                    if (item.type && item.type==='divider') {
                        return <BootstrapDropdown.Divider key={index}/>;
                    }
                    return (
                        <BootstrapDropdown.Item href={item.action} key={index}>
                            {item.title}
                        </BootstrapDropdown.Item>
                )})}

            </BootstrapDropdown.Menu>
        </BootstrapDropdown>
    );
}
Dropdown.propTypes = {
    size: PropTypes.oneOf(Object.keys(SIZE)),
    color: PropTypes.oneOf(COLOR),
    options: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        action: PropTypes.any,
        type: PropTypes.oneOf(['divider','standard']),
    })).isRequired,
    round: PropTypes.bool,
    dir: PropTypes.oneOf(DIRECTION),
};
Dropdown.defaultProps = {
    size: 'md',
};
